<template>
  <div class="max-width">
    <get-in-touch></get-in-touch>
  </div>
</template>

<script>
import GetInTouch from "../../components/core/GetInTouch.vue";
export default {
  components: { GetInTouch },
};
</script>

<style></style>
